<template>
<n-alert v-if="marketplace.localization?.['marketplace/mentorship/info']" class="rounded-md" type="warning" size="small">
    <template #header>
        <n-collapse arrow-placement="right">
            <n-collapse-item>
                <template #header>
                    <div class="text-[10px]" v-html="parsed[0]"></div>
                </template>
                <div
                    class="notes text-[10px]"
                    v-html="parsed.slice(1).join('')">
                </div>
            </n-collapse-item>
        </n-collapse>
    </template>
</n-alert>

<div v-if="!init" class="flex h-full justify-center items-center py-20">
    <n-spin size="small" />
</div>
<div v-else>
    <super-table
        showSearch
        :filters="filters"
        :actions="actions"
        :loading="loadings.table"
        :pager="marketplace.mentorshipPager"
        :columns="marketplace.mentorshipColumns"
        :records="marketplace.mentorshipRecords"
        columnsStorageName="__columns_marketplace-mentors"
        type="marketplace.mentorship"
        @getData="getData"
        @sortColumn="sortColumn"
        @doSomething="doSomething"
        @applyFilters="changeFilter">
        <template #tableTitle>
            <div class="text-sm text-left mb-2">
                {{ marketplace.localization?.['marketplace/mentors/grid/title'] }}
                <span class="text-main font-semibold text-md">{{ marketplace.mentorshipTotal }}</span>
            </div>
        </template>
    </super-table>
</div>

<n-drawer
    :auto-focus="false"
    v-model:show="showModalHelp"
    width="100%"
    class="bg-gray-100 dark:bg-darkbg">
    <n-drawer-content body-content-style="padding: 0 12px;" :auto-focus="false">
        <div class="flex flex-col h-full">
            <div class="relative w-full bg-gray-100 dark:bg-darkbg text-sm text-center text-gray-600 dark:text-white/75 py-4">
                <n-icon
                    size="20"
                    class="text-gray-600 dark:text-white/75 absolute top-1/2 left-0 -translate-y-1/2"
                    @click="showModalHelp = false">
                    <ArrowLeft12Regular />
                </n-icon>
                {{ marketplace.localization?.['marketplace/mentors/grid/actions/inquire_mentorship'] }}
            </div>
            <div class="pb-4 flex-grow overflow-y-scroll overscroll-none scrollbar-hide">
                <n-alert class="rounded-md" type="warning" size="small">
                    <template #header>
                        <n-collapse arrow-placement="right">
                            <n-collapse-item>
                                <template #header>
                                    <div class="text-[10px]" v-html="marketplace.localization?.['marketplace/mentors/dialogs/request_mentorship_agreement']"></div>
                                </template>
                                <div
                                    class="notes text-[10px]"
                                    v-html="requestMentorshipNotes">
                                </div>
                            </n-collapse-item>
                        </n-collapse>
                    </template>
                </n-alert>

                <rb-input
                    class="mt-4"
                    :label="needHelpModel.subject.title"
                    :placeholder="needHelpModel.subject.placeholder"
                    :status="needHelpModel.subject.status === 'error' ? 'error' : undefined"
                    :msg="needHelpModel.subject.msg"
                    v-model:value="needHelpModel.subject.value"
                    @update:value="needHelpModel.subject.status = undefined, needHelpModel.subject.msg = undefined" />

                <div
                    class="rounded-md overflow-hidden mt-4"
                    :class="needHelpModel.question.status === 'error' ? 'border border-red-400' : ''">
                    <quill-editor
                        theme="snow"
                        contentType="html"
                        v-model:content="needHelpModel.question.value">
                    </quill-editor>
                    <div v-if="needHelpModel.question.status === 'error'" class="w-full mt-2 text-red-400 text-right">{{ needHelpModel.question.msg }}</div>
                </div>

                <rb-input
                    class="mt-4"
                    :label="needHelpModel.contact.title"
                    :placeholder="needHelpModel.contact.placeholder"
                    :status="needHelpModel.contact.status === 'error' ? 'error' : undefined"
                    :msg="needHelpModel.contact.msg"
                    v-model:value="needHelpModel.contact.value"
                    @update:value="needHelpModel.contact.status = undefined, needHelpModel.contact.msg = undefined" />
            </div>

            <div class="w-full sticky bottom-0 z-50 py-2 flex justify-end items-center">
                <rb-checkbox
                    :label="needHelpModel.agree.placeholder"
                    :status="needHelpModel.agree.status === 'error' ? 'error' : undefined"
                    v-model:checked="needHelpModel.agree.value"
                    @update:checked="needHelpModel.agree.status = null" />
                <n-button
                    strong
                    class="rounded-md text-white/90"
                    :color="gl.mainColor"
                    :loading="needHelpLoading"
                    :disabled="buttonDisabled || needHelpLoading"
                    @click="requestMentorship">
                    {{ marketplace.localization?.['marketplace/mentors/dialogs/request_mentorship'] }}
                </n-button>
            </div>
        </div>
    </n-drawer-content>
</n-drawer>
</template>

<script>
// general
import general from './general';

// icons
import { Warning24Filled, Dismiss16Filled, ArrowLeft12Regular } from '@vicons/fluent';

// UI
import {
    NIcon,
    NCard,
    NTabs,
    NSpin,
    NAlert,
    NModal,
    NInput,
    NDrawer,
    NButton,
    NTabPane,
    NCheckbox,
    NCollapse,
    NDrawerContent,
    NCollapseItem } from 'naive-ui';

// components
import RbInput from '@components/rb-input/mobile.vue';
import RbCheckbox from '@components/rb-checkbox/mobile.vue';
import SuperTable from '@components/super-table/mobile.vue';

// editor
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
    name: 'mentors-mobile',
    components: {
        NSpin,
        NIcon,
        NCard,
        NTabs,
        NAlert,
        NModal,
        NInput,
        RbInput,
        NButton,
        NDrawer,
        NTabPane,
        NCollapse,
        NCheckbox,
        SuperTable,
        RbCheckbox,
        QuillEditor,
        NCollapseItem,
        NDrawerContent,
        Dismiss16Filled,
        Warning24Filled,
        ArrowLeft12Regular,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>